import { useMetadata } from "hooks/useMetadata";
import dynamic from "next/dynamic";
import { KeyResourcesItemProps } from "components/atoms/KeyResourcesItem";
import { LatestCaseStudy, getPageTranslations } from "utils/server";
import { YuLifeLocale } from "@hooks";
import { StaticRoutes } from "@routes";
import { GetStaticProps } from "next";
import { isLocaleInStaticRoute } from "@utils";
import { Meta } from "components/atoms/Meta";
import { strapiService } from "@services";

export default function AppComponent({
  locale,
  keyResources,
  latestCaseStudy,
}: {
  keyResources: KeyResourcesItemProps[];
  latestCaseStudy: LatestCaseStudy;
  locale: YuLifeLocale;
}): JSX.Element {
  const metadata = useMetadata("app");
  const App =
    locale === "jp"
      ? dynamic(() => import("../../components/pages/_jp/EmployeeApp/index"))
      : dynamic(() => import("../../components/pages/EmployeeApp/index"));

  return (
    <>
      <Meta locale={locale} {...metadata} />
      <App keyResources={keyResources || []} latestCaseStudy={latestCaseStudy} />
    </>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const locale = context.locale as YuLifeLocale;

  if (!isLocaleInStaticRoute(locale, StaticRoutes.featuresEmployeeApp)) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...(await strapiService.getGenericProps(locale)),
      messages: await getPageTranslations(locale, "employee-app"),
    },
  };
};
