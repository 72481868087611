
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/features/employee-app",
      function () {
        return require("private-next-pages/features/employee-app.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/features/employee-app"])
      });
    }
  